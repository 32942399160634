.row.gx-no {
  --bs-gutter-x: 2px !important;
}
.row.gy-no {
  --bs-gutter-y: 0px !important;
}

img {
  max-width: 100%;
}

.column-spacing {
  gap: 70px;
}

.accordian-padding-resources {
  padding: 0px 50px;
}

.accordian-data {
  padding-left: 5px;
}

.design-service-box {
  background-color: light;
  width: 600px;
  height: 350px;
}

.btn-width {
  width: 290px;
}

.pdf-download-btn {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  text-decoration: none !important;
  color: black;
  max-width: 360px;
}
.pdf-download-btn:hover {
  background-color: #eee;
}

.pdf-icon,
.download-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.download-icon {
  margin-left: auto;
}

.video-container iframe {
  width: 100%;
  height: 350px;
}

.video-image {
  width: 100%;
  height: 100%;
}

.alt-features-item {
  margin-top: 50px;
}

.ci-text {
  margin-bottom: 10px;
}

.mobile-column {
  position: relative;
}

.mobile-column:after {
  content: "";
  width: 1px;
  height: 56px;
  background-color: #ccc;
  position: absolute;
  right: 25px;
  top: 0;
}

.contact-info {
  display: flex;
  align-items: start;
  margin-bottom: 15px;
}

.contact-info img {
  margin-right: 10px;
}

.contact-info div {
  margin-left: 10px;
}

.divider-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 1px;
  background-color: #000;
  opacity: 0.2;
  height: 100%;
}

.product-menu-hover .team-item {
  text-decoration: none !important;
}

.product-menu-hover {
  cursor: pointer;
  transition-duration: 1s;
  padding: 15px;
}
.no-pm {
  margin-left: -15px;
  margin-right: -15px;
}

.product-menu-hover a {
  text-decoration: none;
}

.product-menu-hover:hover {
  background: rgba(233, 233, 233, 1);
  cursor: pointer;
  transform: scale(0.9);
}
.product-menu-hover.active {
  background: rgba(233, 233, 233, 1);
  cursor: pointer;
  transform: scale(0.9);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

table th,
table td {
  text-align: left;
  padding: 6px;
  border: 1px solid #ddd;
}

table tr:first-child th {
  font-weight: 400;
}

.mfp-title {
  font-size: 15px;
}

.g-mb-1::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #fff;
}

.attach-cv {
  border-bottom: 1px solid #ccccccf2;
  padding-bottom: 15px;
}
.attach-cv:hover {
  border-bottom: 1px solid #333;
}

.attach-cv label {
  background-color: #2c5b80;
  color: white;
  padding: 10px 20px;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-weight: 600;
}

#file-chosen {
  margin-left: 0.3rem;
}

/* tr:nth-child(odd) {
  background-color: #f2f2f2;
} */

.product-bg-color {
  background-color: rgba(39, 38, 38, 0.5);
}

.card-text-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(39, 38, 38, 0.5);
  font-size: 15px;
  padding: 7px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
}

.card-para-overlay {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 10%;
  text-align: start;
  justify-content: center;
  align-items: center;
  display: flex;
}

.product-card:hover .card-para-overlay {
  opacity: 1;
}

.product-card img {
  width: 100%;
  transition: filter 0.3s ease-in-out;
}

/* .product-card:hover .product-img {
  filter: brightness(50%);
} */

.page-section .home-video-container {
  background-color: #000;
  padding: 60px;
  position: relative;
}

.video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-btn a {
  color: #fff;
  font-size: 26px;
}
.video-btn a:hover {
  color: #fff;
}

.home-video-image {
  width: 90%;
  height: 85vh;
}

.video-overlay {
  width: 100%;
  height: 100%;
  background-color: #0000006b;
  position: absolute;
}

.image-section {
  position: relative;
  text-align: center;
  color: white;
}

.image-wrapper {
  position: relative;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-detail-text-overlay {
  background-color: #383838;
  position: absolute;
  top: 50%;
  right: -5%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  padding: 15px;
  width: 100%;
  max-width: 360px;
}
.product-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100vw;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-card {
  flex: 1 1 calc(20% - 10px);
  position: relative;
  width: 100%;
}

.product-image {
  width: 100px;
  height: auto;
  display: block;
}

.product-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 5px 0;
}

.home-carousel-content {
  position: absolute;
}

.sustainability-text-overlay {
  position: relative;
  padding: 220px 60px;
  max-width: 1480px;
  margin: auto;
  color: #fff;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inline-options {
  display: flex;
  flex-direction: column;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: #2c5b80;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

ul.mn-sub.mn-has-multi {
  width: 100% !important;
}

.product-nav-img {
  width: 100%;
  display: block;
}
.search-input-wrapper {
  display: none;
  position: absolute;
  top: 93%;
  right: 170px;
  margin-top: 5px;
  width: auto;
}

#search-input {
  width: 200px;
  position: relative;
  height: 40px;
  padding: 10px;
  font-size: 16px;
}

#search-button {
  width: 40px;
  height: 50px;
  position: absolute;
}

.trusted-logo .logo-item img {
  max-width: 215px;
}

.copyright-text {
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  color: var(--color-gray-2);
}
.copyright-text a {
  color: var(--color-gray-2);
}
.copyright-text a:hover {
  color: var(--color-gray-2);
}

.bottom-to-top {
  position: fixed;
  bottom: 5%;
  right: 2%;
  padding: 20px;
  display: none;
  cursor: pointer;
  z-index: 9;
}

.footer-social {
  width: 75%;
}

.footer-social a {
  border-radius: 100px;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0px 0;
  font-size: 14px;
  align-items: center;
  background-color: #2c5b80;
  color: #fff;
}

footer .footer-social a {
  margin-right: 10px;
}

.footer-social a:hover {
  background-color: #2c5b80;
  color: #fff;
}

footer a {
  font-size: 16px;
}
footer a:hover {
  color: #000;
}

.footer-logo {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer-logo img {
  height: 36px;
  margin: 0px 8px;
}

footer .fw-title {
  color: #fff;
}

.sustainability-w {
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.sustainability-text-overlay h1 {
  font-size: 60px;
  line-height: 70px;
}

.breadcrumb-w {
  background-color: #efefef;
  padding: 5px 0px;
  margin-top: 86px;
}

.breadcrumb {
  list-style: none;
  display: flex;
  padding: 0;
  font-size: small;
}

.breadcrumb li {
  display: flex;
  align-items: center;
}

.breadcrumb li + li:before {
  content: "/";
  margin: 0 4px;
  color: #000;
}

.breadcrumb a {
  text-decoration: none;
  color: var(--color-dark-1);
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb li:last-child {
  color: var(--color-dark-1);
}

.letters-spacing {
  letter-spacing: 0.1rem;
}

h1.inner-banner-text {
  margin-top: 0;
  font-size: 65px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.pd-slider .owl-controls.clickable {
  opacity: 0;
}

.pd-slider:hover .owl-controls.clickable {
  opacity: 1;
}

.pd-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding-right: 70px;
}

.slider-counting-product-details span {
  font-size: 17px !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.header-serach-w {
  display: flex;
  align-items: center;
  z-index: 9;
}

footer .footer-social a {
  background-color: transparent;
  border: 1px solid #888;
}

footer .footer-social a:hover {
  background-color: transparent;
  color: #fff;
}

/* Slider css */

.product-details-text-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(39, 38, 38, 0.5);
  padding: 10px 15px;
}
.slider-counting {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  color: white;
}
.slider-counting-product-details {
  position: absolute;
  right: 1px;
  bottom: 1px;
  z-index: 10;
  color: white;
  padding: 6px 0px;
  border-left: 1px solid #cccccc7a;
  margin-right: -5px;
  width: 90px;
  justify-content: center;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
}

.slider-counting-home-page {
  position: absolute;
  right: 2%;
  bottom: 3%;
  z-index: 10;
  color: white;
}

.bx-left-arrow-alt {
  font-size: 60px;
}
.bx-right-arrow-alt {
  font-size: 60px;
}

.button-link a {
  display: inline-block;
  transition: transform 0.3s var(--ease-default);
}
.button-link a:hover {
  transform: scale(0.9);
  transition: transform 0.3s var(--ease-default);
}

.bg-gray-light-2 dt > a:after {
  right: 20px !important;
}

.faqs dt a {
  padding-right: 26px !important;
}

.slider .owl-buttons i {
  color: #fff;
}

.owl-controls {
  /* position: relative; */
  bottom: 0;
  left: 0;
  width: 100%;
}

/* .home-section .owl-prev, .owl-next {
  bottom: 0px;
  top: auto;
} */
.home-section .owl-controls {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 100px;
  opacity: 0;
  -webkit-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}
.home-section:hover .owl-controls {
  opacity: 1;
  -webkit-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}
.home-section button.owl-pause-button.sr-only {
  display: none;
}

.small-item-carousel button.owl-pause-button.sr-only {
  display: none;
}

.home-section .owl-buttons i {
  font-size: 32px;
}

.home-section .owl-prev {
  left: 46%;
}

.home-section .owl-next {
  right: 46%;
}

.pd-slider .bg-dark-alpha-60:before,
.bg-dark-alpha-60 .YTPOverlay:before {
  opacity: 0 !important;
}

.pd-slider .owl-buttons i {
  font-size: 28px;
}

.pd-slider button.owl-pause-button {
  position: absolute;
  bottom: 0px;
  right: 95px;
  z-index: 99;
  font-size: 0px;
  height: 45px;
  width: 45px;
  border: none;
  background: transparent;
}

.pd-slider button.owl-pause-button.sr-only::after {
  position: absolute;
  content: "";
  color: #fff;
  width: 45px;
  height: 45px;
  top: 0px;
  left: 0px;
  background-image: url(../images/pause-icon.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}
button.owl-pause-button.sr-only.owl-pause-button-paused::after {
  position: absolute;
  content: "";
  color: rgb(255, 255, 255);
  width: 45px;
  height: 45px;
  top: 0px;
  left: 0px;
  background-image: url(../images/play-icon.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}

.project-view .work-item {
  width: 100%;
}

.home-projects .nav-tabs {
  margin: auto !important;
  display: inline-flex !important;
  justify-content: center;
}

.home-projects .tpl-tabs {
  border-bottom: none !important;
}

.home-projects .tab-left {
  justify-content: left;
}

.home-projects .tpl-tabs > li > .nav-link.active {
  border: none !important;
  position: relative;
}

.home-projects .tpl-tabs > li > .nav-link.active::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: #333;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

.home-projects .nav-tabs .nav-link {
  border: none !important;
  position: relative;
  padding: 6px !important;
  margin: 0px 10px !important;
}
.home-projects .nav-tabs .nav-link::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: var(--color-dark-1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

.home-projects .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none !important;
  position: relative;
}

.job-form-w {
  max-width: 1250px;
}

/* job-form */
.lang-table .lang-row {
  background-color: white;
}

.form-content {
  font-size: 15px;
  border: #969696;
}

.table-container {
  overflow-x: auto;
}

.lang-table {
  width: 100%;
}

.lang-row th,
.lang-row td {
  white-space: nowrap;
  line-height: 1;
}

.input-heading {
  border-bottom: 1px solid #ccc;
  padding: 13px 10px 13px 17px;
  text-transform: uppercase;
  color: var(--color-dark-1);
  margin-bottom: 0;
}

.input-heading .input-small-heading {
  font-size: medium;
  text-transform: none;
}

.input-lg {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 10px;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0;
  border: none;
  font-size: 15px;
}

.input-lg:hover {
  border-bottom: 1px solid #000 !important;
  outline: none;
}
.input-lg:focus {
  border-bottom: 1px solid #000 !important;
  outline: none;
}

.show-job-form {
  display: none;
}

.show-job-form.active {
  display: block;
}
.job-checkbox-1 {
  top: 30%;
  left: 43%;
}
.job-checkbox-2 {
  top: 30%;
  left: 20%;
}
.job-checkbox-3 {
  top: 20%;
  left: 45%;
}

video {
  width: 100%;
}

.susta-text {
  color: #fff;
}
.susta-text:hover {
  text-decoration: none;
  color: #fff;
}

.reso-heading-text {
  color: var(--color-dark-2);
}

/* Product Hover */
.box-details {
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  text-align: center;
  background: rgba(51, 51, 51, 0.65);
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  height: 45px;
  /* transition: height 0.8s ; */
  transition: height 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-size: medium;
  padding: 0px 8px;
}

.box-details strong {
  display: block;
  height: 40%;
  text-transform: uppercase;
  line-height: 28px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  transform: translateY(80%);
  /* transition: transform 0.8s , opacity 0.8s ; */
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1);
  font-weight: 500;
}

.box-details p {
  opacity: 0;
  padding: 0 5px 5px 5px;
  line-height: 18px;
  height: 40%;
  color: #fff;
  transform: translateY(100%);
  /* transition: transform 0.8s , opacity 0.8s; */
  transition: all 1s cubic-bezier(0.37, 0, 0.54, 1);
  line-height: 26px;
  min-height: 8px;
}

.product-card:hover .box-details {
  height: 50%;
}

.product-card:hover .box-details strong {
  transform: translateY(0);
  /* line-height: 30px; */
}

.product-card:hover .box-details p {
  opacity: 1;
  transform: translateY(-50%);
}

.justify {
  text-align: justify;
}

.contact-border-box {
  border: 8px solid #fff;
}

.row.mobile-email-container {
  padding: 10px 0px;
}

.button-link a img {
  max-width: 45px;
}

.pro-thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.freeform a {
  position: fixed;
  z-index: 99999;
  right: -158px;
  top: 45%;
  background-color: #fff;
  padding: 8px 10px;
  display: block;
  border: 1px solid #e0e0e0;
  transition: var(--transition-default);
}
.freeform a:hover {
  right: 0;
  transition: var(--transition-default);
}

.freeform img {
  width: 180px;
}

/*--------------------------------------------banner animation class*/

.fullwidth-slider-fade .owl-item.active .banner_title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 0.7s;
}

.fullwidth-slider-fade .owl-item.active .banner_linetext {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}

.fullwidth-slider-fade .owl-item.active .gentext16 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 1s;
}

.fullwidth-slider-fade .banner_w01 .banner_title01 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 0.5s;
}
.fullwidth-slider-fade .banner_w01 .gentext16 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}

@media (min-width: 425px) and (max-width: 768px) {
  .column-spacing {
    gap: 0px;
    margin-right: 10px;
  }

  .accordian-data {
    padding-right: 25px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .column-spacing {
    gap: 0px;
    margin: 0px;
  }

  .accordian-padding-resources {
    padding: 0px;
  }

  .accordian-data {
    padding-right: 30px;
    padding-left: 30px;
  }

  /* .heading-text {
        font-size: 15px;
    } */
}

@media only screen and (min-width: 1025px) {
  .page-tabs-space {
    padding-top: 40px !important;
    padding-bottom: 45px !important;
  }

  .inner-nav.desktop-nav i {
    display: none;
  }

  /* .inner-nav > ul {
    margin-right: -200px !important;
} */

  .inner-nav > ul > li > a:before {
    content: "";
    position: absolute;
    top: calc(50% + 0.7em);
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.27s var(--ease-default);
  }

  .inner-nav > ul > li > a:hover:before {
    transform: scaleX(1);
  }

  .inner-nav ul li a:hover,
  .inner-nav ul li a.active:before {
    transform: scaleX(1);
  }

  .mn-has-sub.js-opened:before {
    content: "";
    position: absolute;
    top: calc(50% + 0.7em);
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform-origin: 0 50%;
    transition: transform 0.27s var(--ease-default);
    transform: scaleX(1);
  }

  a.mn-has-sub.js-opened {
    position: relative;
  }
}

@media only screen and (max-width: 1360px) {
  .footer-social {
    width: 90%;
  }

  .product-card:hover .box-details p {
    opacity: 1;
    transform: translateY(-60%);
  }

  .box-details strong {
    height: 45%;
  }

  .box-details.box-details-expand p {
    font-size: 12px;
    line-height: 22px;
  }
  .box-details strong {
    line-height: 18px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1024px) {
  .page-tabs-space {
    padding-top: 40px !important;
    padding-bottom: 45px !important;
  }

  h1.inner-banner-text {
    font-size: 40px;
    line-height: 42px;
  }

  /* .mobile-nav-icon {
    position: relative;
    right: -38px;
}
.mobile-search {
    position: relative;
    left: -60px;
} */

  #search-input {
    width: 112%;
    height: 50px;
  }

  .search-input-wrapper {
    right: 0;
    width: 100%;
  }

  #search-button {
    right: -35px;
  }

  .mobile-on .desktop-nav {
    width: 100% !important;
    position: fixed !important;
  }

  .mobile-on .mobile-nav {
    margin-right: -85px !important;
  }

  .main-nav-sub.full-wrapper {
    margin-right: 40px;
  }

  .header-social-icons {
    display: none !important;
  }

  footer .toggle > dt > a {
    color: #fafafa;
    border-bottom: 1px solid #828282;
  }

  footer .toggle > dt > a:after {
    color: #fff;
  }

  .banner-title {
    font-size: 42px;
    line-height: 45px;
  }

  footer .fw-menu {
    padding-top: 3px;
  }

  .product-card:hover .card-para-overlay {
    display: none;
  }
  .button-link a img {
    max-width: 35px;
  }

  .nav-logo-wrap {
    left: 10px !important;
  }

  nav .full-wrapper {
    --full-wrapper-margin-x: 0px;
  }

  .mn-sub {
    background: transparent !important;
  }
  .megamenu-hover .card-text-overlay {
    position: relative;
    text-align: left;
    text-transform: capitalize;
    padding: 9px 22px;
    font-size: 14px;
    color: #ccc;
    background-color: transparent;
  }

  li.mn-sub-multi {
    padding: 0px !important;
  }

  .product-nav-img {
    display: none;
  }

  a.megamenu-hover {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 991px) {
  .footer-logo {
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    display: block;
  }

  .copyright-text {
    padding-bottom: 25px;
    text-align: center;
  }

  footer .toggle > dd ul {
    padding-top: 10px;
  }
}

.mobile-banner {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile-banner {
    display: block;
  }

  .desktop-banner {
    display: none;
  }

  .slider-counting-product-details {
    width: 50px;
  }

  .slider-counting-product-details span {
    font-size: 17px !important;
  }

  .pd-slider button.owl-pause-button {
    right: 55px;
  }

  .pd-slider button.owl-pause-button.sr-only::after {
    top: 1px;
    left: 14px;
  }

  .video-btn a {
    font-size: 12px;
  }

  .footer-logo img {
    height: 26px;
  }

  .home-section .owl-controls {
    bottom: 10px;
  }

  .slider-counting-home-page {
    right: 5%;
    bottom: 3%;
  }

  .pro-thumb {
    max-width: 50%;
    margin: auto;
  }

  .product-details-text-overlay {
    position: relative;
    background-color: #7e7e7e;
  }

  .toggle > dd {
    font-size: 16px !important;
  }

  .slider-counting {
    right: 5%;
  }

  .home-section .owl-prev {
    left: 37%;
  }

  .home-section .owl-next {
    right: 37%;
  }

  .reverse-div {
    display: flex;
    flex-direction: column-reverse;
  }

  .overlay-text {
    width: 100%;
  }

  h1.inner-banner-text {
    font-size: 36px;
    line-height: 40px;
  }

  .product-detail-text-overlay {
    background-color: #383838;
    position: relative;
    top: 225px;
    right: -50%;
  }

  .sustainability-text-overlay h3 {
    font-size: 22px;
  }

  .sustainability-text-overlay h1 {
    font-size: 40px;
    line-height: 55px;
  }

  .sustainability-text-overlay {
    padding: 100px 30px;
  }

  .home-video-container {
    padding: 20px !important;
  }

  .footer {
    display: none;
  }

  .footer-social {
    width: 70%;
  }

  .copyright-text a {
    color: #666;
  }

  .page-section.footer-desktop {
    display: none;
  }

  .mobile-column:after {
    display: none;
  }
}

@media (min-width: 769px) {
  .page-section.footer-mobile {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .alt-features-item {
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2rem !important;
  }
  .pt-sm-4 {
    padding-top: 2rem !important;
  }
}

.full-gallery-view {
  width: 425%;
  margin-top: 15px;
}

.product-item:nth-of-type(4n + 2) .full-gallery-view {
  margin-left: -112%;
}

.product-item:nth-of-type(4n + 3) .full-gallery-view {
  margin-left: -218%;
}

.product-item:nth-of-type(4n + 4) .full-gallery-view {
  margin-left: -326%;
}

@media (max-width: 991px) {
  .product-item:nth-of-type(4n + 3) .full-gallery-view {
    margin-left: 3%;
  }
  .product-item:nth-of-type(4n + 4) .full-gallery-view {
    margin-left: -117%;
  }
  .full-gallery-view {
    width: 210%;
  }
}

.product-desc p {
  line-height: 27px;
  margin-bottom: 15px;
}

.btn-read-more {
  background-color: transparent;
  color: #4a4a4a;
  padding: 0px;
  text-decoration: underline;
  font-size: 18px;
  border: 0px;
}

/* new next-prev button css */
.btn.btn-light.prev-btn {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 9;
  background-color: transparent;
  border: none;
}

.btn.btn-light.next-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 9;
  background-color: transparent;
  border: none;
}

.btn.btn-light.next-btn svg {
  width: 12px;
  color: #fff;
}
.btn.btn-light.prev-btn svg {
  width: 12px;
  color: #fff;
}
.prv-next-button-w {
  opacity: 0;
}
.next-prv-over:hover .prv-next-button-w {
  opacity: 1;
}

/* 404 page */
.title-404 {
  font-size: 120px;
  line-height: 110px;
}
.box-404 {
  background-color: #f2f2f2;
  padding: 50px;
  text-align: center;
}

.product-new-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #66666687;
  color: #fff;
  font-weight: 700;
  padding: 6px 10px 4px 10px;
  text-transform: uppercase;
  font-size: 11px;
}

.gallery-tabs li:first-child {
  margin-left: -10px;
}

.product-table th {
  background-color: #e4e4e4;
  font-weight: 600 !important;
}
