@font-face {
  font-family: "icomoon";
  src: url("/assets/webfonts/icomoon/icomoon.ttf") format("truetype"),
    url("/webfonts/icomoon/icomoon.woff") format("woff"),
    url("/assets/webfonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  transform: rotate(0.003deg);
}

.icon-quotation-mark:before {
  content: "\e934";
}
.icon-star-rate:before {
  content: "\e935";
}
.icon-star-rate-a:before {
  content: "\e936";
}
.icon-play:before {
  content: "\e941";
}
.icon-globe:before {
  content: "\e937";
}
.icon-info:before {
  content: "\e938";
}
.icon-arrow-left2:before {
  content: "\e939";
}
.icon-arrow-right2:before {
  content: "\e93a";
}
.icon-arrow-down:before {
  content: "\e93b";
}
.icon-arrow-up:before {
  content: "\e93c";
}
.icon-arrow-down1:before {
  content: "\e93d";
}
.icon-arrow-up1:before {
  content: "\e93e";
}
.icon-arrow-left1:before {
  content: "\e93f";
}
.icon-arrow-right1:before {
  content: "\e940";
}
.icon-arrow-right:before {
  content: "\e942";
}

.icon-info {
  position: relative;
  top: 0.05em;
}
.icon-arrow-up {
  position: relative;
  left: 0.5px;
}
